import React from 'react';
import { log, error } from 'logger';
import { AuthorizationConsumer } from 'AuthorizationContext.jsx';
import axios from 'axios';
import { ReactComponent as Logo } from 'assets/images/icon-vertical.svg'; 
import { v4 as uuidv4 } from 'uuid';

class Register extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name: '',
      pairingCode: '',
      spectator: false,
      submitDisabled: false,
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked === true : target.value;
    const name = target.name;
    
    if (name === 'pairingCode') value = value.toUpperCase();

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    
    this.setState({ submitDisabled: true }, () => {
      const formData = new FormData(event.target);
      log('Exchanging pairing code for token');
      axios.get('sanctum/csrf-cookie', { baseURL: process.env.REACT_APP_API_URL })
        .then(() => {
          const spectator = formData.get('spectator') === 'on';
  
          let endpoint = 'spectator';
          
          let payload = {
            pairing_code: formData.get('pairingCode'),
            name: uuidv4(),
          }
          
          if (!spectator) {
            payload.name = formData.get('name');
            endpoint = 'player';
          }
                  
          axios.post(`auth/${endpoint}`, payload)
            .then((res) => {
              log('Token received', res);
              let uuid = res.data.player_uuid;
              const mode = spectator ? 'spectator' : 'player';
  
              if (spectator) {
                uuid = res.data.spectator_uuid;
              }
              
              this.props.context.setPlayerAndToken(uuid, res.data.token, mode);
            })
            .catch((err) => {
              error('Token request failed', err)
              this.setState({ error: err.response.data?.message ?? 'Unknown error' });
            })
            .finally(() => {
              this.setState({ submitDisabled: false });
            });
        })
        .catch((err) => {
          error('CSRF request failed', err)
          this.setState({ error: err.response.data?.message ?? 'Unknown error', submitDisabled: false });
        });
    });
  }
  
  render() {
    return (
      <div className="text-center max-w-lg mx-auto px-4 py-10">
        <div>
          <Logo className="mb-8 w-48 mx-auto"/>
        </div>
        <div className="mb-2">
          <h1 className="text-xl font-semibold">Join Lobby</h1>
        </div>
        <hr className="border-4 height max-w-[30px] mx-auto text-tertiary my-5"/>
        <div>
          {this.state.error &&
            <div className="my-4">
              <p className="bg-tertiary font-bold text-white mx-4 my-2 px-4 py-2">
                {this.state.error}
              </p>
            </div>
          }
          <form onSubmit={this.handleSubmit}>
            <div className="mb-2">
              <label>
                <input className="mr-2" type="checkbox" name="spectator" checked={this.state.spectator} onChange={this.handleChange}/>
                Join Audience <span className="uppercase rounded px-2 py-1 bg-tertiary text-sm">Beta</span>
              </label>
            </div>
            { !this.state.spectator &&
              <div className="mb-2">
                <label>
                  Name
                  <br/>
                  <input
                    name="name"
                    className="max-w-xs text-black mt-2 px-4 py-3 rounded form-input w-full text-center focus-tertiary"
                    type="text"
                    autoComplete="off"
                    minLength={2}
                    maxLength={10}
                    required={!this.state.spectator}
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  { this.state.name.length > 0 &&
                    <span className="block mt-2 text-xs">
                      Characters Remaining: { 10 - this.state.name.length }
                    </span>
                  }
                </label>
              </div>
            }
            <div className="mb-2">
              <label>
                Lobby Code
                <br/>
                <input
                  name="pairingCode"
                  type="text"
                  autoComplete="off"
                  className="max-w-xs text-black mt-2 px-4 py-3 rounded form-input w-full text-center focus-tertiary"
                  maxLength={4}
                  required={true}
                  value={this.state.pairingCode}
                  onChange={this.handleChange}
                />
              </label>
            </div>
            <button
              className="btn btn-primary mb-2 mt-4"
              disabled={this.state.submitDisabled}
              type="submit"
            >
              Join
            </button>
          </form>
        </div>
      </div>
    )
  }
}

export default function register() {
  return (
    <AuthorizationConsumer>
      {state => <Register context={state} />}
    </AuthorizationConsumer>
  );
};
